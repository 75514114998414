import {Outlet} from '@remix-run/react'
import {BannerAnnouncement} from '~/components/BannerAnnouncement'
import {GlobalFooter} from '~/components/GlobalFooter'
import {MarketingHeader} from '~/components/header/MarketingHeader'
import {FooterCTA} from './FooterCTA'

export default function Page() {
  return (
    <main className="flex min-h-screen flex-col bg-radix-mauve-black">
      <BannerAnnouncement />
      <MarketingHeader constrainWidth />

      <Outlet />
      <FooterCTA />
      <GlobalFooter mode="marketing" />
    </main>
  )
}
